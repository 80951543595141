import consumer from "./consumer"

consumer.subscriptions.create({channel: "ProgressDownloadChannel"}, {
    connected() {
        // Called when the subscription is ready for use on the server
        console.log("Conectado")
    },

    disconnected() {
        console.log("Desconectado")
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        var _a = $("#progress-bar-download-" + data.id).parent().parent();
        var error = $("#progress-bar-download-" + data.id).data("error")
        var title = $("#progress-bar-download-" + data.id).data("title")
        var url = $("#progress-bar-download-" + data.id).data("url")
        // Called when there's incoming data on the websocket for this channel
        if (data.error) {
            _a.removeClass("disabled")
            NotyError(data.error)
            _a.addClass("error")
            _a.html(`${error} <i class="fa fa-times" />`)
            setTimeout(() => {
                _a.removeClass("disabled")
                _a.html(title)
                _a.removeClass("error")
            }, 3500)
        } else {
            $("#progress-bar-download-" + data.id).css("width", data.progress + "%")
            $("#progress-bar-download-text-" + data.id).html(`${data.progress}% [${data.bytes_downloaded}/${data.total_size}]`)
            if (parseInt(data.progress) == 100) {
                //console.log(data.redirect)
                if(data.redirect)
                    $.get(data.redirect);

            }
        }

    }
});
