// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require rails-ujs
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


Rails.start()
Turbolinks.start()
ActiveStorage.start()
import $ from "jquery";

window.jQuery = $;
window.$ = $;

import "foundation-sites"
import "select2"
import "slick-carousel"
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
import "cocoon-js";

require("jqueryui")
require("packs/foundation.min.js")
require("src/fit_text")
require("src/prism")
require("src/jscolor.js")
require("packs/noty.js")
require("packs/ruc_validations.js")
require("packs/pdf_canvas.js")
require("packs/firma_con_dedo.js")
require("packs/filepicker.js")
require("packs/filepicker-drop.js")
require("packs/filepicker-ui.js")
require("packs/jquery.autocomplete.min.js")

Prism.languages.csv = {
    'csv_value': /[^\r\n",]|"(?:[^"]|"")"(?!")/,
    'csv_separator': /,/
};

global.Noty = require("packs/noty.js");
global.ClipboardJS = require("clipboard/dist/clipboard.min")
global.SumoSelect = require("packs/sumoselect.min.js")
window.connected = false;
$(document).on('turbolinks:before-render', function () {
    // Fix https://github.com/foundation/foundation-rails/issues/290
    $('html').removeClass('is-reveal-open')
})

window.DisabledRemoteButton = () => {
    $(".disabled-remote").on("click", function () {
        $(this).addClass("disabled");
    })
}
$(document).on('turbolinks:load', function () {
    Prism.highlightAll();
    ConsultarRucEvents();
    ValidateRucDni();
    if (document.getElementById("user_rol")) {
        $("#user_rol").change(function () {
            var user_rol = $('#user_rol').val();
            if (user_rol === "GESTOR") {
                $('.permisos_gestor_certificado').show();
            } else {
                $('.permisos_gestor_certificado').hide();
            }
        });
        $("#user_rol").trigger('change');
    }

    var class_sumoselect = document.getElementsByClassName("sumoselect")
    if (class_sumoselect.length > 0) {
        $('.sumoselect').SumoSelect({
            okCancelInMulti: true,
            isClickAwayOk: true,
            selectAll: true,
            placeholder: 'Elegir',
            locale: ['OK', 'Cancelar', 'Elegir todo'],
            captionFormat: '{0} Seleccionado(s)',
            captionFormatAllSelected: '{0} TODO!',
            closeAfterClearAll: true,
            search: true,
            searchText: 'Buscar...',
            noMatch: 'No encontrado "{0}"',
        });
    }

    $("#version_app_sistema_operativo").on("change", function () {
        $.ajax({
            url: "/version_actual",
            type: "POST",
            data: {
                sistema_operativo: $("#version_app_sistema_operativo").val()
            },
            success: function (e) {
                $("#version_app_version").val(e.version)
            }
        })
    });

    if ($("#eventos_programados").length > 0) {
        $.ajax({
            url: "/yanapa/eventos",
            type: "get",
            success: function (e) {
                if (e.success) {
                    $("#eventos_programados").html("");
                    $.each(e.eventos, function (i, event) {
                        var tipo = event.tipo == "MANTENIMIENTO PROGRAMADO" ? "warning" : "alert";
                        var html = "<div class='news " + tipo + "'>"
                        html += "<div class='text-center'>" + event.descripcion + "</div>"
                        html += "</div>";
                        $("#eventos_programados").append(html);
                    });
                }
            },
            error: function (e) {

            }
        })
    }


    $(document).ready(function () {
        jscolor.installByClassName("jscolor");
    });
    ChangeNotaPendiente();
    PrecioCertificado();

    function PrecioCertificado() {
        $(".tipo_de_certificado, .meses_de_vigencia, .tipo_de_almacen").on('change', function () {
            var tipo_de_certificado = $('.tipo_de_certificado option:selected').val();
            var meses_de_vigencia = $('.meses_de_vigencia option:selected').val();
            var tipo_de_almacen = $('.tipo_de_almacen option:selected').val();
            $.post("/certificado/precios", {
                authenticity_token: $('[name="csrf-token"]')[0].content,
                tipo_de_certificado: tipo_de_certificado,
                meses_de_vigencia: meses_de_vigencia,
                para_firma_remota: tipo_de_almacen == "watana-almacen-firma-remota"

            }, function (data) {
                //console.log(data)
                if (data.success) {
                    $(".callout.precio").show()
                    document.getElementById("result_precio_cert").innerHTML = data.precio
                } else {
                    $(".callout.precio").hide()
                    $(".precio-certificado").text("-")
                }
            });
        })
        $(".tipo_de_certificado").trigger('change')
    }

    OrganizationPresent();

    function OrganizationPresent() {
        $(".tipo_de_certificado").on('change', function () {
            var tipo_de_certificado = $('.tipo_de_certificado option:selected').val();
            var form_organizacion = $("#form_organizacion")
            if (tipo_de_certificado == "EMPRESA") {
                form_organizacion.show()
                $(".organizacion_documento_tipo").attr("required", "required");
                $(".organizacion_documento_numero").attr("required", "required");
                $(".organizacion_denominacion").attr("required", "required");
                $(".organizacion_area").attr("required", "required");
                // $(".organizacion_direccion").attr("required", "required");
                // $(".organizacion_departamento").attr("required", "required");
                // $(".organizacion_provincia").attr("required", "required");
                // $(".organizacion_distrito").attr("required", "required");
                $(".titular_suscriptor_profesion_cargo").removeAttr("required");
                $(".titular_suscriptor_profesion_cargo").show();
            } else if (tipo_de_certificado == "PERSONA") {
                form_organizacion.hide()
                $(".organizacion_documento_tipo").removeAttr("required");
                $(".organizacion_documento_tipo").val("");
                $(".organizacion_documento_numero").removeAttr("required");
                $(".organizacion_documento_numero").val("");
                $(".organizacion_denominacion").removeAttr("required");
                $(".organizacion_denominacion").val("");
                $(".organizacion_area").removeAttr("required");
                $(".organizacion_area").val("");
                // $(".organizacion_direccion").removeAttr("required");
                // $(".organizacion_direccion").val("");
                // $(".organizacion_departamento").removeAttr("required");
                // $(".organizacion_departamento").val("");
                // $(".organizacion_provincia").removeAttr("required");
                // $(".organizacion_provincia").val("");
                // $(".organizacion_distrito").removeAttr("required");
                // $(".organizacion_distrito").val("");
                $(".titular_suscriptor_profesion_cargo").removeAttr("required");
                $(".titular_suscriptor_profesion_cargo").val("");
                $(".titular_suscriptor_profesion_cargo").hide();
            } else if (tipo_de_certificado == "PROFESIONAL") {
                form_organizacion.hide()
                $(".organizacion_documento_tipo").removeAttr("required");
                $(".organizacion_documento_tipo").val("");
                $(".organizacion_documento_numero").removeAttr("required");
                $(".organizacion_documento_numero").val("");
                $(".organizacion_denominacion").removeAttr("required");
                $(".organizacion_denominacion").val("");
                $(".organizacion_area").removeAttr("required");
                $(".organizacion_area").val("");
                // $(".organizacion_direccion").removeAttr("required");
                // $(".organizacion_direccion").val("");
                // $(".organizacion_departamento").removeAttr("required");
                // $(".organizacion_departamento").val("");
                // $(".organizacion_provincia").removeAttr("required");
                // $(".organizacion_provincia").val("");
                // $(".organizacion_distrito").removeAttr("required");
                // $(".organizacion_distrito").val("");
                $(".titular_suscriptor_profesion_cargo").attr("required", "required");
                $(".titular_suscriptor_profesion_cargo").show();
            }
        })
        $(".tipo_de_certificado").trigger('change')
    }

    $('.sign_block_working_button').on('click', function () {
        $(".sign_block_working").removeClass("hide");
        $(".sign_block_working_button").addClass("hide");
    });

    $('[data-open="ajax-reveal"]').on('click', function (e) {
        var filename = $(this).data('filename')
        var url = $(this).data('url')
        //console.log(url)
        $('.ajax-reveal-title').text(filename);
        $('object.ajax-reveal-content').attr('data', url);
        $('object.ajax-reveal-content').load(url);
    });

    PrevenirClickArriba();

    function PrevenirClickArriba() {
        //Evitar que al hacer click en un href="#" regrese arriba
        $('a[href="#"]').click(function (e) {
            e.preventDefault();
        });
    };

    $('.banner_slider').slick({
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        dots: true,
        pauseOnHover: false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 520,
            settings: {
                slidesToShow: 1
            }
        }]
    });

    $(document).ready(function () {
        $('.select2').select2();
    });

    $("h1.big").fitText(1.2);
    $("h2.big").fitText(1.5);

    $(function () {
        $(document).ready(function () {
            $(".filter").on("change", function () {
                var filter = $(this).val();
                var type = $(this).attr('type');
                if (filter.length > 0) {
                    window.location = '?' + type + '=' + filter;
                } else {
                    window.location = '?'
                }
            });
        });
    });
    Cocoon();
    LoadClipboardJS();
    LoadFilepond();

    $(document).foundation()
});

window.ChangeNotaPendiente = function () {
    $("#note_pendiente").off("click").on("click", function () {
        var _this = $(this);
        if (_this.prop("checked")) {
            $("#note_para_el_cliente").prop("checked", false);
        }
    })
    $("#note_para_el_cliente").off("click").on("click", function () {
        var _this = $(this);
        if (_this.prop("checked")) {
            $("#note_pendiente").prop("checked", false);
        }
    })
}

window.CargarEventosRecaptchaJson = function () {
    $("#submit-btn").unbind().on('click', function (e) {
        grecaptcha.execute();
    });

}

window.submitInvisibleRecaptchaForm = function () {
    $("#submit-btn").trigger("click")
};

window.Cocoon = function () {
    $(".nested-form a.add_fields").data("association-insertion-position", 'before').data("association-insertion-node", 'this');
    $("form").on('cocoon:after-insert', function () {
        $('.select2').select2();
    });
};

window.LoadClipboardJS = function () {
    $('.copiar_clave_publica').each(function () {
        var $button = $(this);
        var clip = new ClipboardJS(this);
        clip.on('success', function () {
            $button.val('Copiado!');
            $button.addClass('success');
            window.setTimeout(function () {
                $button.val('Copiar');
                $button.removeClass('success');
            }, 3000);
        });
    });
}
window.LoadFilepond = function () {
// Only load Culqi script
    if (document.querySelectorAll("script[src='https://unpkg.com/filepond/dist/filepond.min.js']").length === 0) {
        return
    }

    var auth_token = $('meta[name="csrf-token"]').attr('content');
    var procesar_pdf_button = $(".procesar_pdf_button");
    var procesar_pdf_button_captcha = $(".procesar_pdf_button_captcha");
    var procesar_type = procesar_pdf_button.attr("type")
    var procesar_pdf_response = $('#procesar_pdf_response');
    var loading_check = $(".loading_check");

    // First register any plugins
    $.fn.filepond.registerPlugin(FilePondPluginFileValidateSize);
    $.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
    $.fn.filepond.registerPlugin(FilePondPluginPdfPreview);
    $.fn.filepond.setOptions({
        labelIdle: 'Arrastrar y soltar archivo aquí o <span class="filepond--label-action"> elegir</span>',
        labelFileLoading: 'Cargando...',
        labelFileProcessing: 'Procesando...',
        labelFileProcessingComplete: 'Archivo subido',
        labelTapToCancel: 'Cancelar',
        labelTapToRetry: 'Tocar para cancelar',
        labelTapToUndo: 'Deshacer',
        server: {
            url: '/check-files',
            timeout: 7000,
            process: {
                url: '/process',
                method: 'POST',
                headers: {'X-CSRF-Token': auth_token},
                withCredentials: false,
                onload: (response) => {
                    var response_json = $.parseJSON(response)

                    if (response_json.success === true) {

                        if (procesar_type === "validar") {
                            procesar_pdf_button.text("VALIDAR PDF")
                            procesar_pdf_button_captcha.text("VALIDAR PDF")
                            var url = "/check-files/" + response_json.id + "/validate"

                        } else if (procesar_type === "sellar") {
                            procesar_pdf_button.text("SELLAR PDF")
                            procesar_pdf_button_captcha.text("SELLAR PDF")
                            var url = "/check-files/" + response_json.id + "/stamp"
                        }

                        procesar_pdf_button.attr("url", url)
                    } else {
                        alert(response_json.error)
                    }
                },
                onerror: (response) => response.data,
                ondata: (formData) => {
                    formData.append('type', procesar_type);
                    return formData;
                },
            },
            revert: '/revert',
            restore: null,
            load: null,
            fetch: null,
        },
        allowPdfPreview: true,
        pdfPreviewHeight: 320,
        pdfComponentExtraParams: 'toolbar=0&view=fit&page=1',
        allowMultiple: false,
        allowFileSizeValidation: true,
        maxFileSize: "50MB",
        maxTotalFileSize: "50MB",
        labelMaxFileSizeExceeded: "Arhivo es demasiado grande, permite hasta 50MB",
        labelMaxTotalFileSizeExceeded: "Arhivos son demasiados grandes, permite hasta 50MB",
        labelMaxTotalFileSize: "El tamaño que se permite es {filesize}",
        allowFileTypeValidation: true,
        acceptedFileTypes: ["application/pdf"],
        fileValidateTypeLabelExpectedTypesMap: {'application/pdf': '.pdf'},
        labelFileTypeNotAllowed: 'Tipo de archivo incorrecto, acepta PDF',
    });

    // Turn input element into a pond
    $('.procesar-pdf').filepond();

    // Listen for addfile event
    $('.procesar-pdf').on('FilePond:addfile', function (e) {
        // console.log('file added event', e);
    });

    // Listen for addfile event
    $('.procesar-pdf').on('FilePond:processfiles', function (e) {
        procesar_pdf_button_captcha.removeClass("hide");
    });

    // Listen for addfile event
    $('.procesar-pdf').on('FilePond:removefile', function (e) {
        procesar_pdf_button_captcha.addClass("hide");
        procesar_pdf_response.empty();
    });

    $('.procesar_pdf_button').click(function () {

        var response = grecaptcha.getResponse();
        if (response.length == 0) {
            alert("Error, por favor intente de nuevo")
        } else {
            var url = $(this).attr("url");

            loading_check.removeClass("hide");

            $.ajax({
                url: url,
                type: "POST",
                data: {
                    authenticity_token: auth_token,
                },
                success: function (response) {
                    loading_check.addClass("hide");
                    procesar_pdf_button_captcha.addClass("hide");
                    procesar_pdf_response.empty();

                    if (procesar_type === "validar") {
                        procesar_pdf_response.append(response);
                        procesar_pdf_response.foundation();
                        LoadClipboardJS();

                    } else if (procesar_type === "sellar") {
                        if (response.success === true) {
                            var response = '<a class="button large success" href="' + response.public_url + '" target="_blank">DESCARGAR PDF SELLADO <i class="fa fa-check"></i></a>'
                        } else {
                            var response = response.error
                        }
                        response = '<p class="red text-center lead">' + response + '</p>'
                        procesar_pdf_response.append(response);
                    }

                },
                error: function (e) {
                    console.log(JSON.stringify(e));
                }

            })
        }


    })
}

window.FilePickerFolder = function (maxFileSize, maxFileSizeHuman) {

    askNotificationPermission();
    var authenticity_token = $('#authenticity_token').val();

    //console.log("init filepicker")
    $('#filepicker').unbind().filePicker({
        url: '/documents/upload_document_file/',
        data: function () {
            return {
                "authenticity_token": authenticity_token,

            }
        },
        acceptFileTypes: /(\.|\/)(pdf)$/i,
        maxFileSize: maxFileSize,
        plugins: ['ui', 'drop'],
        uploadMultipleLimit: 2,
        uploadMultiple: false,
        messages: {
            uploadFallback: 'El navegador no soporta subir archivos.',
            minFileSize: 'El archivo no debe ser menor a :min KB.',
            maxFileSize: 'El archivo no debe ser mayor a ' + maxFileSizeHuman,
            postMaxSize: 'El archivo excede el límite de tamaño máximo de :max MB.',
            invalidFileType: 'El tipo de archivo no está soportado. Debe ser formato PDF.',
            error: 'Ha ocurrido un error.',
            abort: 'La operación fue abortada.',
            // UI plugin
            processing: 'Procesando... No cierres esta ventana hasta que termine',
            deleteFail: 'No se puede eliminar :file.',
        },
        ui: {
            perPage: 10,
            refreshPagination: true,
            autoLoad: true
        }
    }).on('load.filepicker', function (e, data) {
        $("#load_picker").addClass("hide");
        //window.limite_subida_master = parseInt(limite);
        console.log("Load")
    }).on('done.filepicker', function (e, data) {
        var token = data.files[0].token;

        if ($("#file_" + token).length > 0) {
            $("#file_" + token).remove()
        } else {
            var conteo = $("#count-files-folder").attr("conteo")
            $("#count-files-folder").attr("conteo", parseInt(conteo) + 1)
            $("#count-files-folder").text((parseInt(conteo) + 1) + " documentos")
        }
        $(".message").remove();
        var list = $('.files');

        // Iterate through the uploaded files
        $.each(data.files, function (i, file) {
            if (file.error) {
                list.append('<div class="message hide" key="warning"> Con error archivo ' + file.name + ' - ' + file.error + '</div>');
            } else {
                list.append('<div class="message hide" key="success"> Se subió el archivo ' + file.name + '</div>');
            }
        });

    }).on('renderupload.filepicker', function (e, data) {
        var upload_count = isNaN(parseInt(localStorage.getItem("upload-count"))) ? 0 : parseInt(localStorage.getItem("upload-count"))
        localStorage.setItem("upload-count", (upload_count + 1))
    }).on('renderdone.filepicker', function (e, data) {
        RefreshStorageDetails();
        ValidarFirmarCarpeta();
        if (e.originalEvent.type == "renderdownload") {
            var upload_count = isNaN(parseInt(localStorage.getItem("upload-count"))) ? 0 : parseInt(localStorage.getItem("upload-count"))
            var ready_count = isNaN(parseInt(localStorage.getItem("ready_count"))) ? 0 : parseInt(localStorage.getItem("ready_count"))

            localStorage.setItem("ready_count", ready_count + 1)
            // SETEAR FUNCIONES LUEGO DE QUE SE RENDERIZAN TODOS Y NO UNO A UNO (LO HACE LENTO)
            if (ready_count == upload_count) {
                if (data.files.length == 0) {

                    $(".files")[0].innerHTML = (
                        '<li id="files_empty">' +
                        '<div style="border-radius: 20px;background-color: #EFEFEF;padding:20px 30px;margin: 20px">' +
                        '<p style="margin:30px;" class="lead text-center">Aún no hay archivos y/o carpetas</p>' +
                        '</div>' +
                        '</li>'
                    )
                } else {
                    $(".files #files_empty").remove()
                }
                setTimeout(function () {

                    $(".pagination a").unbind().on("click", function () {
                        $("#load_picker").removeClass("hide");
                        $(".files tr").remove();
                    })
                    // BINDINGS
                    $(".files .menu-user-link").unbind().on("click", function (e) {
                        e.preventDefault();
                        //return false;
                    })
                }, 200)
                $(document).foundation();
                //console.log("foundation");
            }
        }


    }).on('fail.filepicker', function (e, data) {
        console.log(data)
        // Al mostrar estos mensajes se consume mucha memoría
        // Se habilito solo en esta función, el plugin recarga automaticamente cuando sube y no se logra ver el mensaje de error.
        try {
            NotyError(JSON.parse(data.xhr.responseJSON)[0])
        } catch (e) {
            NotyError(data.xhr.responseText);
        }


    }).on('delete.filepicker', function (e, data) {
        //data.id = data.token_file

    }).on('deletedone.filepicker', function (e, data) {
        ValidarFirmarCarpeta();
        RefreshStorageDetails();
        NotySuccess("Documento " + data.nombre + " eliminado correctamente")
        var conteo = $("#count-files-folder").attr("conteo")
        $("#count-files-folder").attr("conteo", parseInt(conteo) - 1)
        $("#count-files-folder").text((parseInt(conteo) - 1) + " documentos")

        //ActualizarPorcentaje();
    }).on('deleteall.filepicker', function (e, data) {
        if (!confirm("Está seguro de eliminar TODOS los archivos?, esta acción no se puede deshacer.")) {
            return false;
        }
    }).on("renderpagination.filepicker", function (e, data) {
        //console.log("renderpagination")

    });

}

window.descargarFile = (filename, base64Data, content_type) => {

    var binaryString = window.atob(base64Data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }

    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([bytes], {type: content_type});

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    var data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    document.body.appendChild(link); //required in FF, optional for Chrome
    link.href = data;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
}

window.ValidarFirmarCarpeta = () => {
    if ($("#li-volver-documentos").hasClass("hide"))
        return;
    var conteo = document.querySelectorAll('ul.files li:not(#files_empty)').length;
    if (conteo > 0) {
        $("#firmar-carpeta-menu").removeClass("hide");
        $("#descargar-carpeta-menu").removeClass("hide");
    } else {

        $("#descargar-carpeta-menu").addClass("hide");
        $("#firmar-carpeta-menu").addClass("hide");
    }
}
window.NotySuccess = function (msj) {
    new Noty({
        type: "success",
        timeout: 4000,
        text: msj,
        title: "",
        icon: "https://img.icons8.com/fluency/36/ok--v1.png"
    }).show();
}
window.NotyError = function (msj) {
    new Noty({
        type: "alert",
        timeout: 6000,
        text: msj,
        title: "",
        icon: "https://img.icons8.com/fluency/36/high-priority--v1.png"
    }).show();
}
window.NotyPush = function (json) {


    var title = JSON.parse(json)["titulo"]
    var mensaje = JSON.parse(json)["mensaje"]
    var url = JSON.parse(json)["url"]

    if (Notification.permission == "granted") {
        var notification = new Notification(title, {
            body: mensaje,
            icon: window.favicon_url,
        });
        notification.onclick = () => {
            if (url != null)
                window.location.href = url
            notification.close()
        }
    } else {
        new Noty({
            type: "warning",
            timeout: 7000,
            title: title,
            text: mensaje,
            button: url != null,
            button_text: "Click para abrir",
            callbacks: {
                onClick: () => {
                    if (url != null)
                        window.location.href = url

                }
            },
            icon: "https://img.icons8.com/fluency/36/topic-push-notification.png"
        }).show();
    }


}

window.askNotificationPermission = () => {
    // función para pedir los permisos
    function handlePermission(permission) {
        // configura el botón para que se muestre u oculte, dependiendo de lo que
        // responda el usuario
        if (
            Notification.permission === "denied" ||
            Notification.permission === "default"
        ) {
            //notificationBtn.style.display = "block";
        } else {
            //notificationBtn.style.display = "none";
        }
    }

    // Comprobemos si el navegador admite notificaciones.
    if (!("Notification" in window)) {
        console.log("Este navegador no admite notificaciones.");
    } else {
        if (checkNotificationPromise()) {
            Notification.requestPermission().then((permission) => {
                handlePermission(permission);
            });
        } else {
            Notification.requestPermission(function (permission) {
                handlePermission(permission);
            });
        }
    }

    function checkNotificationPromise() {
        try {
            Notification.requestPermission().then();
        } catch (e) {
            return false;
        }

        return true;
    }
}
window.RefreshStorageDetails = () => {
    setTimeout(() => {
        $.get("/home/refresh_storage")
    }, 500)
}

window.modalSocketOK = (message, title = "Operación completada") => {

    $("#modal-socket").html(`
<h4 class="text-center">${title}</h4>
<img width="70" height="70" src="https://img.icons8.com/fluency/96/ok--v1.png" alt="ok--v1"/><br>
<h6>${message}</h6>
<br><button class="button secondary" data-close>Cerrar</button>`)
    $("#modal-socket").foundation("open")

}

window.modalSocketError = (message, title = "Ha ocurrido un error") => {

    $("#modal-socket").html(`
<h4 class="text-center">${title}</h4>
<img width="70" height="70" src="https://img.icons8.com/fluency/96/high-priority--v1.png" alt="high-priority--v1"/><br>
<h6>${message}</h6>
<br><button class="button secondary" data-close>Cerrar</button>`)
    $("#modal-socket").foundation("open")

}

window.RefreshPicker = () => {
    try {
        //console.log("RefreshPicker")
        if ($("#filepicker").length > 0) {
            //console.log("filepicker refresh")
            $("#filepicker").filePicker().plugins.ui.fetchFiles();
        }


        if ($("#list_signs").length > 0) {
            //console.log("list_signs refresh")
            $.get(`/solicitudes/${localStorage.getItem("signs_filter_current")}.js`)
        }

    } catch (e) {
        console.log(e)
    }

}

window.ShowProcesingTransaction = (json) => {
    var conteo = JSON.parse(json)["conteo"]
    var total = JSON.parse(json)["total"]
    var error = JSON.parse(json)["error"]
    if (error) {
        $("#transaction-socket").removeClass("hide");
        $("#transaction-socket").find(".progress").addClass("hide")
        $("#transaction-title").html("Operación abortada")
        $("#transaction-socket").find(".callout").removeClass("secondary")
        $("#transaction-socket").find(".callout").removeClass("success")
        $("#transaction-socket").find(".callout").addClass("alert")
        setTimeout(() => {
            $("#transaction-socket").addClass("hide");
            $("#transaction-text").text("");
            $("#transaction-title").html("");
            $("#transaction-progress").css("width", `0%`)
            $("#transaction-socket").find(".callout").addClass("secondary")
            $("#transaction-socket").find(".callout").removeClass("success")
            $("#transaction-socket").find(".callout").removeClass("alert")
        }, 5000);
    } else {

        $("#transaction-socket").removeClass("hide");
        $("#transaction-socket").find(".progress").removeClass("hide")
        var porcentaje = parseInt((conteo / total) * 100);
        $("#transaction-title").html(`Transacción en progreso: ${conteo} de ${total} <i class="fa fa-spinner fa-spin" />`)
        $("#transaction-text").text(`${porcentaje}%`)
        $("#transaction-progress").css("width", `${porcentaje}%`)
        if (conteo == total) {

            $("#transaction-title").html(`Transacción finalizada <i class="fa fa-check" />`)
            $("#transaction-text").text(`Firmados ${conteo} de ${total} (${porcentaje}%)`)
            $("#transaction-socket").find(".callout").removeClass("secondary")
            $("#transaction-socket").find(".callout").removeClass("alert")
            $("#transaction-socket").find(".callout").addClass("success")
            $("#transaction-socket").find(".progress").addClass("hide")
            setTimeout(() => {
                $("#transaction-socket").addClass("hide");
                $("#transaction-text").text("");
                $("#transaction-title").html("");
                $("#transaction-progress").css("width", `0%`)
                $("#transaction-socket").find(".callout").addClass("secondary")
                $("#transaction-socket").find(".callout").removeClass("success")
                $("#transaction-socket").find(".callout").removeClass("alert")
            }, 5000);

        }
    }


}

window.CrearSolicitud = () => {

    var pagina_pdf = 1;
    if ($("li.pagina.selected").length > 0) {
        pagina_pdf = parseInt($("li.pagina.selected")[0].id.replace("pag-", ""));
    }
    var sign_id = $("#document-for-sign").attr("sign")
    var certificate_id = $("#document-for-sign").attr("certificate-id");
    var document_id = $("#document-for-sign").val();
    var tipo = $("#document-for-sign").attr("tipo");
    var firmante = $("#document-for-sign").attr("firmante");
    var firma_visual = $("#document-for-sign").attr("firma-visual");
    var pagina = tipo == "folder" ? $("#document-for-sign").attr("pagina") : pagina_pdf;
    var posicion = $("#document-for-sign").attr("posicion");
    var email = $("#firmante_email").val();
    var x = $("#sign_x").val() ? $("#sign_x").val() : null;
    var y = $("#sign_y").val() ? $("#sign_y").val() : null;
    var imagen = ""
    if (certificate_id == "0") {
        imagen = localStorage.getItem("img-firma-electronica");
        if (!imagen) {
            NotyError("No has configurado una imagen para la firma electrónica")
            return;
        }
        imagen = imagen.replace(/^data:image\/[a-z]+;base64,/, "")
    }
    $.post(`/documents/${document_id}/sign_folder_file`, {
        sign_id,
        certificate_id,
        tipo,
        firmante,
        firma_visual,
        pagina,
        posicion,
        email,
        imagen,
        x,
        y
    });
}

window.sinErrorImg = (elem) => {
    //console.log("sinErrorImg");
    var alt = elem.parentNode.children.item(0)
    if (alt.tagName.toLowerCase() != "span")
        return
    elem.parentNode.removeChild(alt)
    elem.style.display = "block"
}

window.conErrorImg = (elem) => {
    //console.log("conErrorImg");
    var alt = document.createElement("span");
    alt.appendChild(document.createTextNode(elem.getAttribute('alt')))
    var ant = elem.parentNode.children.item(0)
    if (ant.tagName.toLowerCase() == "span") {
        return;
    }
    elem.parentNode.insertBefore(alt, elem);

    elem.style.display = "none"
}


